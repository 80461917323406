Array::remove = ->
    what = undefined
    a = arguments
    L = a.length
    ax = undefined
    while L and @length
        what = a[--L]
        while (ax = @indexOf(what)) != -1
            @splice ax, 1
    this

# ---
# generated by js2coffee 2.2.0
$(document).ready ->
    $('[data-toggle="tooltip"]').tooltip()
    #    AOS.init();
    $(window).scroll ->
        if $(this).scrollTop() > 160
            $('body').addClass 'scroll'
        else
            $('body').removeClass 'scroll'

    visitedOffer = localStorage.getItem('visitedOffer')
    if !visitedOffer
        arr = []
        visitedOffer = JSON.stringify(arr)
        localStorage.setItem('visitedOffer', visitedOffer)
    if offerId?
        visitedOffer = JSON.parse(localStorage.getItem('visitedOffer'))
        if !visitedOffer.includes(offerId)
            visitedOffer.push(offerId)
            localStorage.setItem('visitedOffer', JSON.stringify(visitedOffer))
    $('.slick.slick-4').slick(
        slidesToShow: 4,
        slidesToScroll: 1
        autoplay: true
        dotted: true
        autoplaySpeed: 2000
        pauseOnHover: false
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true
                }
            }]
    );
    $('.extended-toggler').on('click', ()->
        target = $(this).data('target')
        $('[data-target="' + target + '"]').toggleClass('d-none')
        $('[id*="' + target + '_tgl"]').toggleClass('d-none')
    )
    $('#toggleMenu').on('click', ()->
        $(this).toggleClass('is-active')
        $('body').toggleClass('mobile-menu-open')
        console.log('xxx')
    )
    $('.slick.slick-3').not('.center-mode').slick(
        slidesToShow: 3,
        slidesToScroll: 1
        autoplay: true
        dotted: true
        pauseOnHover: false
        autoplaySpeed: 2000);
    $('.slick.slick-3.center-mode').slick(
        slidesToShow: 3,
        slidesToScroll: 1
        autoplay: true
        dotted: true
        centerMode: true
        centerPadding: '0'
        autoplaySpeed: 5000
        pauseOnHover: false
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true
                    centerMode: false
                }
            }]
    );
    $('.piece-of-cake').each((key, data)->
        $(this).addClass('piece-of-cake-' + key)
    )
    $('.search-box__tab').on('click', ->
        $('.search-box__tab--current').removeClass('search-box__tab--current')
        $(this).addClass('search-box__tab--current')
        toShow = $(this).data('id')
        $('#key_0').val(toShow)
#        updateUrl()
    )

    #    $('.slick-counter').on 'afterChange', () ->
    countUp = (objectCase)->
        slide = $(objectCase).find('.slick-current .slick-header')
        id = $(slide).attr('id')
        countTo = parseInt($(slide).data('count-to'))
        if countTo < 50
            countDelay = 10
            countStep = 1
        else if countTo < 1000
            countDelay = 10
            countStep = 2
        else if countTo < 10000
            countDelay = 10
            countStep = 100
        else
            countDelay = 10
            countStep = 200
        if !$(slide).hasClass('counted')
            setInterval(()->
                now = parseInt($('#' + id).html());
                if now < countTo
                    now = now + countStep;
                    $('#' + id).html(now)
                else
                    $(slide).addClass('counted')
                    $('[data-id*=' + id + ']').html(now)
            , countDelay)
    countUp('.slick-counter')

    $('.center-mode.history').on 'afterChange', () ->
        $('.history .slick-slide').each((key, item)->
            $(this).removeClass('slide-color-0').removeClass('slide-color-1').removeClass('slide-color-2')
        )
        $('.history .slick-active').each((key, item)->
            $(item).addClass('slide-color-' + key)
        )
    $('.slick-counter').on 'afterChange', () ->
        countUp(this)
    $('.slick-counter').on 'swipe', () ->
        countUp(this)
    $('.share-url').on('click', ()->
        text = $('#link-to-share').val()
        navigator.clipboard.writeText(text).then (->
            alert "Skopiowano link do schwoka, możesz go wkleić w dowolnym miejscu"
            return
        ), (err) ->
            return
    )
    $('.btn-toggle').on('click', ()->
        $(this).toggleClass('btn-primary').toggleClass('btn-outline-primary')
        target = $(this).data('target')
        value = $(this).data('value')
        searchString = []
        $('.btn-primary[data-target=' + target + ']').each((key, item)->
            searchString[key] = $(item).data('value')
        )
        $('#' + target).val(searchString.join(','))
    )
    countFav = () ->
        $('.favourite-toggle').each((key, item)->
            id = $(item).data('id')
            additionalClass = ''
            if $(this).children('i').data('add')
                additionalClass = $(this).children('i').data('add')
            if localStorage.getItem("observed_" + id)
                classToPut = 'fas fa-bookmark text-brand ' + additionalClass
                $(this).html('<i class="' + classToPut + '" data-add="' + additionalClass + '"></i>');
            else
                classToPut = 'far fa-bookmark text-brand ' + additionalClass
                $(this).html('<i class="' + classToPut + '" data-add="' + additionalClass + '"></i>');
        )
    countFav()
    $('.btn-addons-toggle').on('click', ()->
        $('.hideable').toggleClass('d-none')
        $('.btn-addons-toggle').toggleClass('d-none')
    )

    if $('#contact-form-inquiry').length > 0 && localStorage.getItem('observed_items')
        data = JSON.parse(localStorage.getItem('observed_items'))
        items = []
        data.forEach((item)->
            items.push({
                name: localStorage.getItem('observed_name_' + item),
                url: localStorage.getItem('observed_' + item)
            })
        )
        $('#contact-form-inquiry').val(JSON.stringify(items))
    if $('#favourites-container').length > 0
#        $('#favourites-container').html(localStorage.getItem('observed_items'))
        collection = JSON.parse(localStorage.getItem('observed_items'))
        if collection && collection.length > 0
            collection.forEach((item, key)->
                if url = localStorage.getItem('observed_' + item)
                    $.get(url + ".json", (data)->
                        console.log(data)
                        message = "<div class='" + data.schema + " pb-5 text-left'>"
                        if data? && (data.data?.key_34 || data.data?.key_35)
                            message += '<div class="product-row-sold">'
                        message += "<div class='position-relative img-thumbnail mb-4'>"
                        message += "<a href=\"javascript:void(0)\" class=\"favourite-toggle position-absolute\" data-uri='" + data.uri + "' data-id='" + data.id + "' data-name='" + data.data?.offerId + "' style='top:20px;left:20px'><i class='fas fa-bookmark text-brand' data-add='fa-3x'></i></a>"
                        message += "<img src=" + data.image + " class='img-fluid'>"
                        message += "</div>"
                        message += "<div class='d-md-flex'>"
                        message += "<div class='flex-fill'>"
                        message += "<div class='mb-3'><h5>" + data.data.title + "</h5></div>"
                        message += "<div class='mb-3'><strong>Powierzchnia użytkowa</strong></div>"
                        message += "<div class='mb-3'>" + data.data.key_9 + "m<sup>2</sup></div>"
                        message += "<div class='mb-3'><strong>Piętro</strong></div>"
                        message += "<div>" + data.data.key_7 + "</div>"
                        message += "</div>"
                        message += "<div class='mh-100'>"
                        message += "<div class='d-flex justify-content-between flex-column h-100'>"
                        message += "<div class='mb-5'><a href='" + data.parentUri + "' class='mb-5'><img src=" + data.parentImage + " class='img-fluid'></a></div>"
                        message += "<div class='mt-5'><a href='" + data.uri + "' class='btn btn-outline-primary d-block'>Zobacz&nbsp;więcej</a></div>"
                        message += "</div>"
                        message += "</div>"
                        message += "</div>"
                        message += "</div>"
                        if data && (data.data?.key_34 || data.data?.key_35)
                            message += "</div>"
                        message += "<hr class='mb-md-5'/>"
                        if $('#favourites-container').children('i').length > 0
                            $('#favourites-container').children('i').remove()
                        $('#favourites-container').append(message)
                        countFav()
                    , 'json')
            )
        else
            if $('#favourites-container').children('i').length > 0
                $('#favourites-container').children('i').remove()
            $('#favourites-container').html('<p class="alert alert-info text-center mt-5">Dodaj mieszkanie do ulubionych przez przycisk <i class="fas fa-bookmark"></i> </p>')
    $('body').on('click', '.favourite-toggle', ()->
        id = $(this).data('id')
        url = $(this).data('uri')
        name = $(this).data('name')
        additionalClass = ''
        collection = []
        if items = localStorage.getItem('observed_items')
            collection = JSON.parse(items)
        if $(this).children('i').data('add')
            additionalClass = $(this).children('i').data('add')
        if localStorage.getItem("observed_" + id)
            localStorage.removeItem("observed_" + id)
            localStorage.removeItem("observed_name_" + id)
            collection.remove(id)
            classToPut = 'far fa-bookmark text-brand ' + additionalClass
            $(this).html('<i class="' + classToPut + '"  data-add="' + additionalClass + '"></i>');
            localStorage.setItem('observed_items', JSON.stringify(collection))
        else
            localStorage.setItem("observed_" + id, url)
            localStorage.setItem("observed_name_" + id, name)
            classToPut = 'fas fa-bookmark text-brand ' + additionalClass
            $(this).html('<i class="' + classToPut + '"  data-add="' + additionalClass + '"></i>');
            collection.push(id)
            localStorage.setItem('observed_items', JSON.stringify(collection))
    )

    extract = (fieldName, target)->
        values = $(fieldName).val()
        if $(fieldName).length > 0 && values.length > 0
            values.split(',').forEach((item, key)->
                $('.btn-outline-primary[data-target=' + target + '][data-value=' + item + ']').toggleClass('btn-primary').toggleClass('btn-outline-primary')
            )
    extract('#key_7', 'key_7')
    extract('#addons', 'addons')
    maps = []
    markers = []
    $('.map-container').each((key, item)->
        lat = $(this).data('lat');
        lng = $(this).data('lng');
        id = $(this).data('id')
        markerImg = $(this).data('marker');
        devhero = {lat: lat, lng: lng};
        maps[key] = new google.maps.Map(document.getElementById('mapFullWidth-' + id), {
            zoom: 16,
            center: devhero,
            scrollwheel: false,
            draggable: true,
            styles: [{"elementType": "geometry", "stylers": [{"color": "#f5f5f5"}]},
                {"elementType": "labels.icon", "stylers": [{"visibility": "on"}]},
                {"elementType": "labels.text.fill", "stylers": [{"color": "#616161"}]},
                {"elementType": "labels.text.stroke", "stylers": [{"color": "#f5f5f5"}]}, {
                    "featureType": "administrative.land_parcel",
                    "elementType": "labels.text.fill",
                    "stylers": [{"color": "#bdbdbd"}]
                }, {"featureType": "poi", "elementType": "geometry", "stylers": [{"color": "#eeeeee"}]},
                {"featureType": "poi", "elementType": "labels.text.fill", "stylers": [{"color": "#757575"}]}, {
                    "featureType": "poi.attraction",
                    "elementType": "labels.icon",
                    "stylers": [{"color": "#ffae3d"}, {"visibility": "on"}]
                }, {
                    "featureType": "poi.business",
                    "elementType": "labels.icon",
                    "stylers": [{"color": "#23d100"}, {"visibility": "on"}]
                }, {
                    "featureType": "poi.government",
                    "elementType": "labels.icon",
                    "stylers": [{"color": "#008a3c"}, {"visibility": "on"}]
                }, {"featureType": "poi.medical", "elementType": "labels.icon", "stylers": [{"color": "#ffea00"}]},
                {"featureType": "poi.park", "elementType": "geometry", "stylers": [{"color": "#e5e5e5"}]}, {
                    "featureType": "poi.park",
                    "elementType": "labels.icon",
                    "stylers": [{"color": "#ffa200"}, {"visibility": "on"}]
                }, {"featureType": "poi.park", "elementType": "labels.text.fill", "stylers": [{"color": "#9e9e9e"}]}, {
                    "featureType": "poi.place_of_worship",
                    "elementType": "labels.icon",
                    "stylers": [{"visibility": "on"}]
                }, {
                    "featureType": "poi.school",
                    "elementType": "labels.icon",
                    "stylers": [{"color": "#ffa200"}, {"visibility": "on"}]
                }, {
                    "featureType": "poi.sports_complex",
                    "elementType": "labels.icon",
                    "stylers": [{"color": "#ffa200"}, {"visibility": "on"}]
                }, {"featureType": "road", "elementType": "geometry", "stylers": [{"color": "#ffffff"}]},
                {"featureType": "road.arterial", "elementType": "labels.text.fill", "stylers": [{"color": "#757575"}]},
                {"featureType": "road.highway", "elementType": "geometry", "stylers": [{"color": "#dadada"}]},
                {"featureType": "road.highway", "elementType": "labels.text.fill", "stylers": [{"color": "#616161"}]},
                {"featureType": "road.local", "elementType": "labels.text.fill", "stylers": [{"color": "#9e9e9e"}]},
                {"featureType": "transit.line", "stylers": [{"visibility": "on"}]},
                {"featureType": "transit.line", "elementType": "geometry", "stylers": [{"color": "#e5e5e5"}]},
                {"featureType": "transit.station", "elementType": "geometry", "stylers": [{"color": "#eeeeee"}]},
                {"featureType": "transit.station", "elementType": "labels.icon", "stylers": [{"visibility": "on"}]},
                {"featureType": "water", "elementType": "geometry", "stylers": [{"color": "#c9c9c9"}]},
                {"featureType": "water", "elementType": "labels.icon", "stylers": [{"color": "#ffa200"}]},
                {"featureType": "water", "elementType": "labels.text.fill", "stylers": [{"color": "#9e9e9e"}]}]
        });
        markers[key] = new google.maps.Marker({
            position: devhero,
            icon: {url: markerImg},
            map: maps[key]
        })
    )
#    $('body').on('mouseenter', ()->
#        $('img[usemap]').rwdImageMaps()
#        $('img[usemap]').maphilight(mapConfig)
#    )
    $('.clickable-map').on('mouseleave', ()->
        config = $(this).data('maphilight')
        if config
            config['fillOpacity'] = 0.5
            $(this).data('maphilight',config)
            $('img[usemap]').maphilight(mapConfig)
    )
    $('.clickable-map').on('mouseenter', ()->
        id = $(this).data('id')
        href = $(this).data('href')
        config = $(this).data('maphilight')
        if config
            config['fillOpacity'] = 0.8

            $(this).data('maphilight',config)
        $('img[usemap]').maphilight(mapConfig)
        $.get(href + ".json", (data)->
            message = "<div class='" + data.schema + " pb-5 text-left'>"

            message += "<div class='d-md-flex'>"
            message += "<div class='position-relative mb-4'>"
            message += "<a href=\"javascript:void(0)\" class=\"favourite-toggle position-absolute\" data-uri='" + data.uri + "' data-id='" + data.id + "' data-name='" + data.data?.offerId + "' style='top:20px;left:20px'><i class='fas fa-bookmark text-brand fa-2x' data-add='fa-2x'></i></a>"
            if data.image
                message += "<img src=" + data.image + " class='img-fluid  img-thumbnail' style='max-width:200px'>"
            message += "</div>"
            message += "<div class='flex-fill p-3'>"

            message += "<div class='mb-3'><h5>" + data.data.title + "</h5></div>"
            message += "<div class='flex-fill'>"
            message += "<div class='d-flex justify-content-between'><div class='mb-3'><strong>Powierzchnia użytkowa</strong></div><div>" + data.data.key_9 + "m<sup>2</sup></div></div>"
            message += "<div class='d-flex justify-content-between'><div class='mb-3'><strong>Piętro</strong></div><div>" + data.data.key_7 + "</div></div>"
            message += "</div>"
            message += "<div class='d-flex justify-content-between flex-column h-100'>"
            message += "<div class='mt-5'><a href='" + data.uri + "' target='_blank' class='btn btn-outline-primary d-block'>Zobacz&nbsp;więcej</a></div>"
            message += "</div>"
            message += "</div>"
            message += "</div>"
            message += "</div>"
            message += "<hr class='mb-md-5'/>"

            $('#flatContainer').html(message)

        , 'json')
    )
    #    $(window).trigger('resize');
    reloadMaps = false
#    $(window).scroll ->
#        if reloadMaps == false
#            $('img[usemap]').maphilight(mapConfig)
#            $('img[usemap]').rwdImageMaps()
#            reloadMaps = true
#
#    clearTimeout

    $('img[usemap]').rwdImageMaps()
    resizeEvt = setTimeout((->
        $('img[usemap]').maphilight(mapConfig)
        return
    ), 200)
#    $(document).on 'ready.usemaps', ->
#        console.log 1
#        $('img[usemap]').rwdImageMaps()
#        $('img[usemap]').maphilight(mapConfig)
#        return
#    $(window).on 'resize.usemaps', ->
#        clearTimeout resizeEvt
#        resizeEvt = setTimeout((->
#            $('img[usemap]').maphilight(mapConfig)
#            return
#        ), 200)
#        return


